import React, {useState, useEffect} from "react";
import {Box, Button, Container, Grid2 as Grid, TextField, Typography, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {LoginState} from "../../data-types";
import {useNavigate, useSearchParams, Link} from "react-router-dom";
import {useAccountCreate, useLogin} from "../../hooks";
import FocusRingLogo from "../../img/focus_ring_logo.png";
import {toast} from "react-toastify";
import {formatError, DataAccess} from "../../util";

export function CreateAccount() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const {accountState, handleChange, createAccount, handleQueryParams, params} = useAccountCreate();
    const {login} = useLogin();
    const [loading, setLoading] = useState<boolean>(false);

    // on mount, check query parameters.  validate url and optionally initialize state
    useEffect(() => {
        // if you don't decode this first, you get a mapping of the entire query string to a blank string, i.e.:
        // {"param1=askdflsajnf&param2=kdfafnda..." => ""}
        // I think there should be support for useSearchParams() to parse base64-encoded search params, but
        // I didn't find anything in the documentation.  Also, there's an extra equal sign appended for some reason
        const decoded = decodeURIComponent(searchParams.toString());
        if (decoded) {
            const newParams = new URLSearchParams(decoded);
            if (newParams.has("checksum")) {
                // need to get prior params
                const _params = decoded.split("&");
                const checksumRaw = _params.pop() as string; // removes checksum
                const checksum = checksumRaw.split("=")[1] + "=";
                const queryParams = _params.join("&"); // join back together
                const validateUrlData = {
                    partnerUrl: {
                        clientId: "DEFAULT",
                        queryString: queryParams,
                        checksum: checksum
                    }
                }

                DataAccess.post("/api/validateUrl/validate.json", {data: validateUrlData})
                    .then(_ => {
                        // this user came from coalition
                        handleQueryParams(decoded);
                    })
                    .catch(_ => {
                        // simply reload the page
                        navigate("/create-account");
                    })
            } else {
                // don't validate if no checksum
                handleQueryParams(decoded);
            }
        }
    }, [searchParams, navigate, handleQueryParams])

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await createAccount({...accountState, params: params});
            const loginState: LoginState = {"username": accountState.username, "password": accountState.password};
            const loginDestination = await login(loginState);
            navigate(loginDestination);
        } catch(e) {
            toast.error(formatError(e))
            setLoading(false);
        }
    }

    const queryParams = decodeURIComponent(searchParams.toString());

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            minWidth: "350px",
            mt: {xs: "20%", sm: "5%"},
            mb: 5
        }}>
            <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center" sx={{px: {xs: 0, sm: "5%", md: "20%"}}}>
                <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                    <Box
                        component="img"
                        sx={{
                            width: 190
                        }}
                        alt="FocusRing"
                        src={FocusRingLogo}
                    />
                </Grid>
                <form onSubmit={handleSubmit}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h5" sx={{textAlign: "center"}}>
                            Create an Account
                        </Typography>
                        <TextField
                            required
                            label="First Name"
                            name="firstname"
                            fullWidth
                            slotProps={{
                                htmlInput: {size: 35}
                            }}
                            onChange={handleChange}
                            sx={{mt: 3}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Last Name"
                            name="lastname"
                            fullWidth
                            slotProps={{
                                htmlInput: {size: 35}
                            }}
                            onChange={handleChange}
                            sx={{mt: 2}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Email"
                            name="email"
                            disabled={!!params.email}
                            fullWidth
                            slotProps={{
                                htmlInput: {size: 35}
                            }}
                            onChange={handleChange}
                            sx={{mt: 2}}
                            value={accountState.email}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Username"
                            name="username"
                            fullWidth
                            slotProps={{
                                htmlInput: {size: 35}
                            }}
                            onChange={handleChange}
                            sx={{mt: 2}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Password"
                            name="password"
                            fullWidth
                            slotProps={{
                                htmlInput: {
                                    type: "password",
                                    autoComplete: "new-password",
                                    size: 35
                                }
                            }}
                            onChange={handleChange}
                            sx={{mt: 2}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Confirm Password"
                            name="confirmPassword"
                            fullWidth
                            slotProps={{
                                htmlInput: {
                                    type: "password",
                                    autoComplete: "new-password",
                                    size: 35
                                }
                            }}
                            onChange={handleChange}
                            sx={{mt: 2}}
                        />
                        <Typography variant="subtitle2" sx={{p: 2}}>
                            * Passwords must be at least 12 characters in length and contain at least 1 each of uppercase letters, lowercase letters, special characters, and numbers.
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}} sx={{textAlign: 'center', mb: 4}}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={loading}
                            onClick={handleSubmit}
                            sx={{mt: 5}}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                    <Grid size={{xs: 12}} sx={{textAlign: "center", mb: 4}}>
                        <Button
                            color="primary"
                            component={Link}
                            to={`/login${queryParams !== "" ? `?${queryParams}` : ""}`}
                        >
                            Already have an account?  Sign in
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </Container>
    )
}