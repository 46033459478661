import React, {useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {
    Home,
    Login,
    AdminDashboard,
    AdminCreateCompetition,
    AdminCompetitions,
    AdminCompetitionDetail,
    AdminCreateRound, PerceptionCube, AdminScripts,
    AdminVideoPrompt,
    AdminVideoPromptDetail,
    AdminRecordVideoPrompt, AdminUploadVideoPrompt,
    AdminCreateScript, AdminEditScript,
    AdminScriptDetail,
    UserProfile,
    Analytics,
    CreateAccount,
    PasswordRecovery,
    PasswordResetPage,
    AdminReviewGroups,
    AdminReviewGroupsDetail,
    AdminReviewGroupsCreate,
    AdminUserDetail,
    ReviewerCompetitions,
    Reviews,
    AdminProxyReviewResults,
    ReviewerDashboard,
    ConfirmEmail,
    ConfirmEmailReminder,
    AdminCompetitionResults, AdminResults, AdminUserResults, AdminUserReviews, AdminProxyRoundResults,
    Responses,
    ResponseDetail,
    Worklist,
    AdminRoundConfig,
    AdminRoundDetail,
    AdminReportDetail,
    AdminScriptStepDetail,
    AdminResponseDetail,
    AdminUsers,
    AdminEditRound,
    AdminOptionalScriptDetail,
    VideoResponse,
    VideoReview,
    MyResults,
    Rankings,
    Results,
    MyEvents,
    Leaders,
    Enroll,
    Feedback,
    Receipt,
    PaymentSuccess,
    WordcloudPage
} from "./pages";
import {WithNav, WithoutNav, PrivateRouter, AdminRouter, ReviewerRouter, NotFound,
    ViewerRouter, PromptAdminRouter, PromptScriptAdminRouter, EventAdminRouter} from "./util";
import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {FocusRingTheme} from "./theme";
import {UrlConfig} from "./util";
import {UserProfileState} from "./data-types";
import {UserContext, MarketingContext, BrandingContext} from "./context";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const [user, setUser] = useState<UserProfileState | null>(null);
    const [marketingId, setMarketingId] = useState<string | null>(null);
    // for branding in the nav bar
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const baseUrl = UrlConfig.getBaseUrl();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FocusRingTheme}>
                <CssBaseline />
                <ToastContainer
                    position="bottom-left"
                    theme="dark"
                />
                <MarketingContext.Provider value={{marketingId: marketingId, setMarketingId: setMarketingId}}>
                    <UserContext.Provider value={{user: user, setUser: setUser}}>
                        <BrandingContext.Provider value={{logoUrl: logoUrl, setLogoUrl: setLogoUrl}}>
                            <BrowserRouter basename={baseUrl} >
                                <Routes>
                                    <Route element={<WithoutNav />}>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/create-account" element={<CreateAccount />} />
                                        <Route path="/password-recovery" element={<PasswordRecovery />} />
                                        <Route path="/password-recovery/:resetToken" element={<PasswordResetPage />} />
                                        <Route path="/confirmEmail/reminder/:email" element={<ConfirmEmailReminder />}  />
                                        <Route path="/confirmEmail/:confirmEmailToken" element={<ConfirmEmail />} />
                                    </Route>
                                    <Route element={<PrivateRouter />}>
                                        <Route element={<WithNav />}>
                                            <Route path="/" element={<Home />}/>
                                            <Route path="/user/profile" element={<UserProfile />} />
                                            <Route path="/analytics" element={<Analytics />} />
                                            <Route path="/enroll" element={<Enroll />} />
                                            <Route path="/myResults/:competitionId?" element={<MyResults />}>
                                                <Route path="rankings" element={<Rankings />} />
                                                <Route path="results" element={<Results />} />
                                                <Route path="reviewsOfMe" element={<Reviews />} />
                                            </Route>
                                            <Route path="/leaders/:competitionId?" element={<Leaders />} />
                                            <Route path="/myEvents" element={<MyEvents />} />
                                            <Route path="/feedback" element={<Feedback />} />
                                            <Route path="/video-review/:competitionId" element={<VideoReview isProxy={false} />} />
                                            <Route path="/video-response/:competitionId" element={<VideoResponse />} />
                                            <Route path="/perception-cube" element={<PerceptionCube />} />
                                            <Route path="/responses" element={<Responses />} />
                                            <Route path="/responses/:responseId" element={<ResponseDetail />} />
                                            <Route path="/worklist" element={<Worklist />} />
                                            <Route path="/receipt/:competitionId" element={<Receipt />} />
                                            <Route path="/paymentSuccess/:competitionId" element={<PaymentSuccess />} />
                                            <Route element={<ViewerRouter />}>
                                                <Route path="/admin" element={<AdminDashboard />} />
                                                <Route path="/admin/events" element={<AdminCompetitions />} />
                                                <Route path="/admin/events/detail/:competitionId" element={<AdminCompetitionDetail />} />
                                                <Route path="/admin/events/results/:competitionId" element={<AdminResults />}>
                                                    <Route path="rankings" element={<Rankings />} />
                                                    <Route path="reports" element={<AdminCompetitionResults />} />
                                                    <Route path="results" element={<AdminUserResults />} />
                                                    <Route path="wordcloud" element={<WordcloudPage />} />
                                                    <Route path="reviews" element={<AdminUserReviews />} />
                                                    <Route path="proxyResults" element={<AdminProxyRoundResults />} />
                                                </Route>
                                                <Route path="/admin/round/detail/:roundId" element={<AdminRoundDetail />} />
                                                <Route path="/admin/round/detail/:roundId/scriptStep/detail/:scriptStepId" element={<AdminScriptStepDetail />} />
                                                <Route path="/admin/videoPrompts" element={<AdminVideoPrompt />} />
                                                <Route path="/admin/videoPrompts/detail/:videoId" element={<AdminVideoPromptDetail /> } />
                                                <Route path="/admin/proxyReviewResults/:roundId" element={<AdminProxyReviewResults />} />
                                                <Route path="/admin/response/detail/:responseId" element={<AdminResponseDetail />} />
                                                <Route path="/admin/round/detail/:roundId/optionalScriptStep/detail/:scriptStepId" element={<AdminOptionalScriptDetail />} />
                                                <Route path="/admin/scripts" element={<AdminScripts />} />
                                                <Route path="/admin/scripts/detail/:scriptId" element={<AdminScriptDetail />} />
                                            </Route>
                                            <Route element={<PromptAdminRouter />}>
                                                <Route path="/admin/videoPrompts/record" element={<AdminRecordVideoPrompt />} />
                                                <Route path="/admin/videoPrompts/upload" element={<AdminUploadVideoPrompt />} />
                                            </Route>
                                            <Route element={<PromptScriptAdminRouter />}>
                                                <Route path="/admin/scripts/create/:scriptId?" element={<AdminCreateScript />} />
                                                <Route path="/admin/scripts/edit/:scriptId?" element={<AdminEditScript />} />
                                            </Route>
                                            <Route element={<EventAdminRouter />}>
                                                <Route path="/admin/events/:action" element={<AdminCreateCompetition />} />
                                                <Route path="/admin/events/:action/:competitionId" element={<AdminCreateCompetition />} />
                                                <Route path="/admin/events/createRound/:competitionId/:roundNumber" element={<AdminCreateRound />} />
                                                <Route path="/admin/round/config/:roundId" element={<AdminRoundConfig />} />
                                                <Route path="/admin/round/edit/:competitionId/:roundId" element={<AdminEditRound />} />
                                                <Route path="/admin/reviewGroups" element={<AdminReviewGroups />} />
                                                <Route path="/admin/reviewGroups/detail/:reviewGroupId?" element={<AdminReviewGroupsDetail />} />
                                                <Route path="/admin/reviewGroups/create" element={<AdminReviewGroupsCreate />} />
                                                <Route path="/admin/userDetail/:userId" element={<AdminUserDetail />} />
                                                <Route path="/admin/report/detail/:responseId" element={<AdminReportDetail />} />
                                            </Route>
                                            <Route element={<AdminRouter />}>
                                                <Route path="/admin/users" element={<AdminUsers />} />
                                            </Route>
                                            <Route element={<ReviewerRouter />}>
                                                <Route path="/reviewer" element={<ReviewerDashboard />} />
                                                <Route path="/reviewer/competitions" element={<ReviewerCompetitions />} />
                                                <Route path="/reviewer/video-proxy-review/:competitionId" element={<VideoReview isProxy={true} />} />
                                                <Route path="/reviewer/proxyReviewResults/:roundId" element={<AdminProxyReviewResults />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </BrowserRouter>
                        </BrandingContext.Provider>
                    </UserContext.Provider>
                </MarketingContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
