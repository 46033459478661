import React, {useState, useEffect, useContext} from "react";
import {DataAccess} from "../../util";
import {ReviewerCompetitionCard, NoDataCard, LoadingSpinner } from "../../components";
import {Container, Grid2 as Grid, Typography } from "@mui/material";
import { Competition } from "../../data-types";
import {UserContext} from "../../context";

export function ReviewerCompetitions() {
    const {user} = useContext(UserContext);
    const [competitions, setCompetitions] = useState<Competition[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        DataAccess.get("/api/proxyReview/getList.json")
            .then(r => {
                setCompetitions(r.competitions);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
            })
    }, []);

    if (loading) {
        return <LoadingSpinner />
    }

    // render results only if user is admin
    const renderResults = user ? (user.role === "admin" || user.role === "event_admin") : false;

    return (
        <Container sx={{mb: 10}}>
            <Typography variant="h4" sx={{mb: 8}}>
                Reviews
            </Typography>
            <Grid container spacing={6}>
                {competitions.map((c, i) => {
                        return (
                            <Grid size={{xs: 12}} key={i}>
                                <ReviewerCompetitionCard
                                    competitionMeta={c.competitionMeta!}
                                    roundStatus={c.roundStatus!}
                                    roundMeta={c.roundMeta!}
                                    renderResults={renderResults}
                                />
                            </Grid>
                        )
                })}
                {competitions.length === 0 ?
                    <Grid size={{xs: 12}}>
                        <NoDataCard message={"You have not been added as a reviewer to an event."} />
                    </Grid> :
                    null
                }
            </Grid>
        </Container>
    )
}