import {Entry} from "@plussub/srt-vtt-parser/dist/src/types";
import {VideoJsPlayerOptions} from "video.js";
import {Video, VideoSource} from "../../data-types";

export const DataFormatting = {

    // 1 -> 1st, 2 -> 2nd, etc.
    intToOrdinal: function(i: number) {
        let j = i % 10;
        let k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    },

    millisecondsToTimeString: function(ms: number) {
        const seconds = Math.floor(ms / 1000);
        const milliseconds = (ms / 1000).toFixed(3).split(".")[1];
        return this.secondsToTimeString(seconds) + `.${milliseconds}`;
    },

    capitalizeFirstChar: function(s: string): string {
        return s.charAt(0).toUpperCase() + s.slice(1);
    },

    roleIdToString: function(roleId: number): string {
        let result: string;
        switch (roleId) {
            case 1:
                result = "User";
                break;
            case 2:
                result = "Viewer";
                break;
            case 3:
                result = "Admin";
                break;
            case 4:
                result = "Prompt Admin";
                break;
            case 5:
                result = "Prompt + Script Admin";
                break;
            case 6:
                result = "Event Admin";
                break;
            default:
                result = "";
                break;
        }
        return result;
    },

    serializeToVtt: function(entries: Entry[]) {
        return entries.reduce<string>((p, c, i) => {
            return p + `\n${i + 1}\n${this.millisecondsToTimeString(c.from)} --> ${this.millisecondsToTimeString(c.to)}\n${c.text}\n`;
        }, "WEBVTT\n")
    },

    // turn seconds into hh:mm:ss string
    secondsToTimeString: function(s: number) {
        const hours = Math.floor(s / 3600);
        const minutes = Math.floor((s - (hours * 3600)) / 60);
        const seconds = s % 60;
        return (hours ? `${hours}:` : "") +
            (minutes < 10 ? `0${minutes}:` : `${minutes}:`) +
            (seconds < 10 ? `0${seconds}` : `${seconds}`);
    },

    // implementation of python-like zip.  arrays of different size
    // will result in padded undefined values
    zip: function<T, U>(arr1: T[], arr2: U[]): [T | undefined, U | undefined][] {
        return Array(Math.max(arr1.length, arr2.length)).fill(undefined).map((_, i) => [arr1[i], arr2[i]]);
    },

    createTranscriptUrl: function(transcript: string) {
        let transcriptUrl: string;
        try {
            transcriptUrl = `data:text/vtt;charset=UTF-8,${encodeURIComponent(transcript)}`;
        } catch (_) {
            transcriptUrl = "";
        }
        return transcriptUrl;
    },

    videoToVideoJSOptions: function(video: Video): VideoJsPlayerOptions {
        const transcriptUrl = this.createTranscriptUrl(video.transcript);
        return {
            autoplay: false,
            controls: true,
            userActions: {hotkeys: true},
            sources: video.sources,
            fill: true,
            tracks: [{
                src: transcriptUrl,
                srclang: "en",
                label: "English",
                default: true
            }],
            html5: {
                nativeTextTracks: false
            },
        };
    },

    videoSourceToVideoJSOptions: function(videoSource: VideoSource): VideoJsPlayerOptions {
        return {
            autoplay: false,
            controls: true,
            userActions: {hotkeys: true},
            sources: [videoSource],
            fill: true,
            html5: {
                nativeTextTracks: false
            },
        }
    },

    getRecordingMimeType: function(): string {
        if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
            return "video/webm;codecs=vp9,opus";
        }
        else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
            return "video/webm;codecs=vp8,opus";
        } else {
            return "video/mp4";
        }
    },

    // giga, mega, kilo, byte
    intToByteString: function(bytes: number): string {
        if (bytes < 1000) {
            return `${bytes} B`;
        } else if (bytes < 1000000) {
            return `${(bytes / 1000).toFixed(2)} KB`
        } else if (bytes < 1000000000) {
            return `${(bytes / 1000000).toFixed(2)} MB`
        } else {
            return `${(bytes / 1000000000).toFixed(2)} GB`;
        }
    }
}