import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import {Unauthorized} from "./Unauthorized";
import {UserContext} from "../../context";
import {UserRole} from "../../data-types";

export const PromptAdminRouter = () => {
    const {user} = useContext(UserContext);

    const isUserPromptAdmin = (role: UserRole) => {
        return role === "admin" || role === "ps_admin" || role === "p_admin" || role === "event_admin";
    }
    return (user && isUserPromptAdmin(user.role)) ? <Outlet /> : <Unauthorized />
}
