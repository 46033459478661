import {useState, useCallback, useEffect, ChangeEvent, KeyboardEvent, useContext} from "react";
import {
    AdminCompetitionMeta,
    CreateCompetitionState,
    FileUploadConfig,
    InvitationDetail,
    RequestStatus,
    Promotion,
    CachedCompetitionState
} from "../../data-types";
import {parseISO, isAfter, addMinutes} from "date-fns";
import {DataAccess, DataValidation} from "../../util";
import {UserContext} from "../../context";

export const useCompetitionCreate = (competitionId: string | undefined, action: string | undefined) => {
    const {user} = useContext(UserContext);
    const [competitionState, setCompetitionState] = useState<CreateCompetitionState>(() => {
        const now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);
        return {
            competitionName: "",
            competitionDescription: "",
            tags: [],
            visibilityDate: addMinutes(now, 24 * 60),
            endDate: addMinutes(now, 22 * 24 * 60), // three weeks and 1 day
            accessibility: "public",
            invitationEmails: [],
            whitelistedSuffixes: [],
            blacklistedEmails: [],
            requiresProfile: false,
            requiresUsResident: false,
            isSingleInstSelection: false,
            paymentParticipant: 0,
            prizeDescription: "",
            promoCode: "",
            promoDescription: "",
            promoUrl: "",
            institutionId: user && user.institutionId ? user.institutionId : -1
        }
    });
    // cache create state when doing edit.  Certain behavior, such as editing the visibility date, requires the old
    // version of the state retrieved on page load, if exists.
    const [cachedState, setCachedState] = useState<CachedCompetitionState | null>(null);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {

        const getExisting = async () => {
            if ((action === "edit" || action === "copy") && competitionId) {
                const {competitionAdminDetail} = await DataAccess.get(`/api/competition/${competitionId}/detailAdmin.json`);
                const meta = competitionAdminDetail.competitionMeta as AdminCompetitionMeta;
                const invitationDetail = competitionAdminDetail.invitationDetail as InvitationDetail;
                const promotionData = competitionAdminDetail.promotionData as Promotion;
                const cachedState: CachedCompetitionState = {
                    competitionId: parseInt(competitionId),
                    competitionName: meta.name,
                    competitionDescription: meta.description,
                    tags: meta.tags.map(c =>  c.name),
                    visibilityDate: parseISO(meta.visibilityDate + "Z"),
                    endDate: parseISO(meta.endDate + "Z"),
                    accessibility: meta.accessibility,
                    invitationEmails: [...invitationDetail.users.map(c => c.email), ...invitationDetail.candidates.map(c => c.email)],
                    whitelistedSuffixes: meta.whitelistedSuffixes,
                    blacklistedEmails: meta.blacklistedEmails,
                    requiresProfile: meta.requiresProfile,
                    requiresUsResident: meta.requiresUsResident,
                    isSingleInstSelection: meta.isSingleInstitution ?? false,
                    legalAgreementName: meta.legalAgreementOrigFilename ?? "",
                    legalAgreementUuid: meta.legalAgreementUuid ?? "",
                    paymentParticipant: meta.paymentParticipant ?? 0,
                    prizeDescription: meta.prizeDescription ?? "",
                    promoCode: promotionData?.promoCode ?? "",
                    promoDescription: promotionData?.description ?? "",
                    promoUrl: promotionData?.url ?? "",
                    institutionId: meta.institutionId ?? -1,
                    promoLogo: promotionData?.logo ?? null
                }
                // set both current state and cached state
                setCompetitionState(cachedState);
                setCachedState(cachedState);
            }
        }

        getExisting()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });
    }, [competitionId, action]);

    const handleCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        setCompetitionState(prev => {
            return {
                ...prev,
                [name]: checked
            }
        })
    }, []);

    const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        // event.target.value is a string type.  for number types in state, check if
        // it can be converted to a number.  if so, then use that for value,
        // else use the string representation
        let value: string | number = (isNaN(e.target.valueAsNumber) ? e.target.value : e.target.valueAsNumber);
        setCompetitionState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const handleCurrencyChange = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
        const key = e.key;
        if (!isNaN(parseInt(key, 10))) {
            setCompetitionState(prev => {
                return {
                    ...prev,
                    paymentParticipant: parseInt(prev.paymentParticipant.toString() + key)
                }
            })
        } else if (key === "Backspace" || key === "Delete") {
            setCompetitionState(prev => {
                return {
                    ...prev,
                    paymentParticipant: prev.paymentParticipant < 10 ? 0 : parseInt(prev.paymentParticipant.toString().slice(0, -1))
                }
            })
        } else {
            return;
        }
    }, []);

    const handleDateChange = useCallback((dateKey: string, dateValue: Date | null) => {
        if (dateValue) {
            setCompetitionState(prev => {
                return {
                    ...prev,
                    [dateKey]: dateValue
                }
            })
        }
    }, []);

    const handleAddTag = useCallback((t: string) => {
        const trimmed = t.trim();
        if (trimmed.length > 0) {
            setCompetitionState(prev => {
                return {
                    ...prev,
                    tags: [...prev.tags, trimmed]
                }
            });
        }
    }, []);

    const handleRemoveTag = useCallback((t: string) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                tags: prev.tags.filter(c => c !== t)
            }
        })
    }, []);

    const handleAddWhitelist = useCallback((t: string) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                whitelistedSuffixes: [...prev.whitelistedSuffixes, t.trim()]
            }
        })
    }, []);

    const handleRemoveWhitelist = useCallback((t: string) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                whitelistedSuffixes: prev.whitelistedSuffixes.filter(c => c !== t)
            }
        })
    }, [])

    const submitCompetition = useCallback(async (competition: CreateCompetitionState, uploadConfig: FileUploadConfig | null, promoUploadConfig: FileUploadConfig | null) => {
        const validationChecks = async () => {
            const now = new Date();
            // check if end date is after start date
            if (isAfter(competition.visibilityDate, competition.endDate)) {
                throw new Error("Please make sure your Visibility Date is before your End Date.");
            }

            if (isAfter(now, competition.visibilityDate) || isAfter(now, competition.endDate)) {
                throw new Error("Visibility and End dates must be set in the future.")
            }

            if (competition.competitionName === "") {
                throw new Error("Please give your event a name.");
            }
            if (competition.paymentParticipant < 0) {
                throw new Error("Cost per participant cannot be negative.");
            }
            // if competition has payment, then must use previously-uploaded agreement or new via uploadConfig
            if (competition.paymentParticipant > 0 && uploadConfig === null && !competition.legalAgreementUuid) {
                throw new Error("Events with payment must have a legal agreement.");
            }
            if ((competition.prizeDescription.match(/\n/g)?.length || 0) > 2) {
                throw new Error("Please limit the prize description to 3 lines maximum.");
            }

            // promo checks
            if ((promoUploadConfig !== null || competition.promoCode) && (competition.promoUrl === "" || competition.promoDescription === "")) {
                throw new Error("Promos must have a URL and a description.");
            }

            if ((competition.promoUrl !== "" || competition.promoDescription !== "") && competition.promoCode === "") {
                throw new Error("Promo code required.  Add a promo code or remove the URL and Description.");
            }

            if (competition.promoUrl !== "" && !DataValidation.isValidWebAddress(competition.promoUrl)) {
                throw new Error("Invalid Promo URL.");
            }
        }

        await validationChecks();

        // create form data
        const formData = new FormData();
        const data = {competition:
                {
                    ...competition,
                    blacklistedEmails: (competition.accessibility !== "invite-only") ? competition.blacklistedEmails : [],
                    institutionId: competition.institutionId === -1 ? null : competition.institutionId,
                    promoCode: competition.promoCode === "" ? null : competition.promoCode
                }
        }
        formData.append("competition", new Blob([JSON.stringify(data)], { type: "application/json" }));

        if (uploadConfig) {
            formData.append("legalAgreementFile", uploadConfig.file);
        }
        if (promoUploadConfig) {
            formData.append("promoLogoFile", promoUploadConfig.file);
        }

        let r;
        if (action === "edit") {
            r = await DataAccess.post("/api/competition/update.json", {data: formData});
        } else {
            r = await DataAccess.post("/api/competition/create.json", {data: formData});
        }
        return r.competition;
    }, [action]);

    const handleAddBlacklistEmails = useCallback((emails: string[]) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                blacklistedEmails: [...prev.blacklistedEmails, ...emails]
            }
        });
    }, []);

    const handleRemoveBlacklistEmail = useCallback((email: string) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                blacklistedEmails: prev.blacklistedEmails.filter(s => s !== email)
            }
        })
    }, []);

    const handleAddInvitationEmails = useCallback((emails: string[]) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                invitationEmails: [...prev.invitationEmails, ...emails]
            }
        });
    }, []);

    const handleRemoveInvitationEmail = useCallback((email: string) => {
        setCompetitionState(prev => {
            return {
                ...prev,
                invitationEmails: prev.invitationEmails.filter(s => s !== email)
            }
        })
    }, []);

    return {
        competitionState: competitionState,
        cachedState: cachedState,
        handleTextChange: handleTextChange,
        handleCurrencyChange: handleCurrencyChange,
        handleDateChange: handleDateChange,
        handleAddTag: handleAddTag,
        handleCheck: handleCheck,
        handleRemoveTag: handleRemoveTag,
        handleAddWhitelist: handleAddWhitelist,
        handleRemoveWhitelist: handleRemoveWhitelist,
        handleAddBlacklistEmails: handleAddBlacklistEmails,
        handleRemoveBlacklistEmail: handleRemoveBlacklistEmail,
        handleAddInvitationEmails: handleAddInvitationEmails,
        handleRemoveInvitationEmail: handleRemoveInvitationEmail,
        submitCompetition: submitCompetition,
        requestStatus: requestStatus
    }
}