import React, {useState, useEffect, useContext} from "react";
import {Button, Container, Divider, Grid2 as Grid, Tabs, Tab, Typography} from "@mui/material";
import {usePagination, useVideoPromptDelete, useVideoPromptShare} from "../../hooks";
import {Video} from "../../data-types";
import {VideoAccessType} from "../../data-types";
import {Link} from "react-router-dom";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import UploadIcon from '@mui/icons-material/Upload';
import {VideoLibrary, ShareVideoModal, ConfirmActionModal} from "../../components";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context";

export function AdminVideoPrompt() {

    const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [videoAccessType, setVideoAccessType] = useState<VideoAccessType>("created");
    const [selectedVideoId, setSelectedVideoId] = useState<number>(NaN);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const {
        toShareWith,
        canShare,
        handleChange,
        submitShare,
        reset
    } = useVideoPromptShare();

    const {
        submitDelete
    } = useVideoPromptDelete();

    // myPrompts
    const {
        currentPageNumber: myPromptsPageNumber,
        pages: myPromptsPages,
        totalRecords: myPromptsTotalRecords,
        changePage: myPromptsChangePage,
        loading: myPromptsLoading,
        requestStatus: myPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getMyPrompts.json");

    // sharedPrompts
    const {
        currentPageNumber: sharedPromptsPageNumber,
        pages: sharedPromptsPages,
        totalRecords: sharedPromptsTotalRecords,
        changePage: sharedPromptsChangePage,
        loading: sharedPromptsLoading,
        requestStatus: sharedPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getSharedPrompts.json");

    // publicPrompts
    const {
        currentPageNumber: publicPromptsPageNumber,
        pages: publicPromptsPages,
        totalRecords: publicPromptsTotalRecords,
        changePage: publicPromptsChangePage,
        loading: publicPromptsLoading,
        requestStatus: publicPromptsRequestStatus
    } = usePagination<Video>("/api/videoPrompt/PAGE_NUMBER/getPublicPrompts.json");

    // use effect only runs if all initial pagination requests succeed, else default to created tab
    useEffect(() => {
        if (myPromptsRequestStatus === "complete" && sharedPromptsRequestStatus === "complete" && publicPromptsRequestStatus === "complete") {
            if (myPromptsTotalRecords > 0) {
                setVideoAccessType("created");
            } else if (sharedPromptsTotalRecords > 0) {
                setVideoAccessType("shared");
            } else if (publicPromptsTotalRecords > 0) {
                setVideoAccessType("public");
            } else {
                setVideoAccessType("created");
            }
        }
    }, [myPromptsRequestStatus, myPromptsTotalRecords, sharedPromptsRequestStatus, sharedPromptsTotalRecords, publicPromptsTotalRecords, publicPromptsRequestStatus]);

    const handleShareClick = (id: number) => {
        setSelectedVideoId(id);
        setShareModalOpen(true);
    }

    const handleDeleteClick = (id: number) => {
        setSelectedVideoId(id);
        setDeleteModalOpen(true);
    }

    const shareAction = () => {
        submitShare(toShareWith, selectedVideoId, canShare)
            .then(_ => {
                toast.success("Video Shared Successfully.");
                shareModalClose(false);
            })
            .catch(e => {
                console.log(e);
                toast.error("There was a problem sharing this video");
            })
    }

    // wrapper around ModalProps.setOpen so we can nullify useVideoPromptShare values
    const shareModalClose = (open: boolean) => {
        if (!open) {
            setShareModalOpen(open);
            reset();
        }
    }

    const deleteAction = () => {
        submitDelete(selectedVideoId)
            .then(_ => {
                toast.success("Video Deleted Successfully");
                setDeleteModalOpen(false);
                navigate(0);
            })
        .catch(e => {
            console.log(e);
            toast.error(formatError(e));
        })
    }

    // handler for the 3 item tab bar (My Scripts, Shared With Me, Public Templates
    // so we know what to render
    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setVideoAccessType(newValue as VideoAccessType);
    }

    const canCreate = !!user && (
        user.role === "admin" || user.role === "p_admin" || user.role === "ps_admin" || user.role === "event_admin"
    )

    return (
        <Container sx={{mt: 5, mb: 10}}>
            <Typography variant="h4">
                Video Library
            </Typography>
            <Grid container spacing={2} sx={{textAlign: "center"}}>
                <Grid size={{xs: 12}} sx={{textAlign: "center"}}>
                    <Tabs
                        value={videoAccessType}
                        onChange={handleTabChange}
                        sx={{mb: 5}}
                        centered
                    >
                        <Tab value="created" label={`My Videos ${(myPromptsTotalRecords) ? `(${myPromptsTotalRecords})` : ""}`} disabled={!canCreate} />
                        <Tab value="shared" label={`Shared With Me ${(sharedPromptsTotalRecords) ? `(${sharedPromptsTotalRecords})` : ""}`} />
                        <Tab value="public" label={`Public Videos ${(publicPromptsTotalRecords) ? `(${publicPromptsTotalRecords})` : ""}`} />
                    </Tabs>
                </Grid>
                {canCreate ?
                    <Grid container size={{xs: 12}} spacing={2} justifyContent="center">
                        <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={"/admin/videoPrompts/record"}
                                startIcon={<VideoCallIcon />}
                            >
                                Record
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={"/admin/videoPrompts/upload"}
                                startIcon={<UploadIcon />}
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid> :
                    null
                }
            </Grid>
            <Divider sx={{mb: 5, mt: 5}} variant="middle" />
            {videoAccessType === "created" ?
                <VideoLibrary
                    videosPerPage={6}
                    totalVideos={myPromptsTotalRecords}
                    pageNumber={myPromptsPageNumber}
                    videos={myPromptsPages[myPromptsPageNumber]}
                    loading={myPromptsLoading}
                    changePage={myPromptsChangePage}
                    handleShareClick={handleShareClick}
                    handleDeleteClick={handleDeleteClick}
                /> : null
            }
            {videoAccessType === "shared" ?
                <VideoLibrary
                    videosPerPage={6}
                    totalVideos={sharedPromptsTotalRecords}
                    pageNumber={sharedPromptsPageNumber}
                    videos={sharedPromptsPages[sharedPromptsPageNumber]}
                    loading={sharedPromptsLoading}
                    changePage={sharedPromptsChangePage}
                    handleShareClick={handleShareClick}
                /> : null
            }
            {videoAccessType === "public" ?
                <VideoLibrary
                    videosPerPage={6}
                    totalVideos={publicPromptsTotalRecords}
                    pageNumber={publicPromptsPageNumber}
                    videos={publicPromptsPages[publicPromptsPageNumber]}
                    loading={publicPromptsLoading}
                    changePage={publicPromptsChangePage}
                /> : null
            }
            <ShareVideoModal
                value={toShareWith}
                handleChange={handleChange}
                open={shareModalOpen}
                setOpen={shareModalClose}
                canShare={canShare}
                action={shareAction}
            />
            <ConfirmActionModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={deleteAction}
                confirmActionText="Delete Video"
            >
                <Grid container>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Delete Video Prompt
                        </Typography>
                        <Typography variant="subtitle2">
                            Are you sure you want to delete this?  This cannot be undone.
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmActionModal>
        </Container>
    )
}
