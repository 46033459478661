import {SyntheticEvent, useState} from "react";
import {useTheme, Container, TextField, Grid2 as Grid, Box, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import FocusRingLogo from "../../img/focus_ring_logo.png";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useLogin} from "../../hooks";
import {toast} from "react-toastify";

export function Login() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const {loginState, handleChange, login} = useLogin();
    const [loggingIn, setLoggingIn] = useState<boolean>(false);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoggingIn(true);
        try {
            const loginDestination = await login(loginState);
            navigate(loginDestination);
        } catch(e) {
            setLoggingIn(false);
            console.log(e);
            toast.error("Sorry, that username and password didn't work.  Please try again.");
        }
    }

    const queryParams = decodeURIComponent(searchParams.toString());

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            mt: {xs: "25%", sm: "17%", md: "12%"}
        }}>
            <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                <Grid size={{xs: 12}} sx={{mt: 3, pl: 0, textAlign: "center"}}>
                    <Box
                        component="img"
                        sx={{
                            width: 190
                        }}
                        alt="FocusRing"
                        src={FocusRingLogo}
                    />
                </Grid>
                {/* We need to wrap this in a form to be able to submit on enter */}
                <form onSubmit={handleSubmit} style={{paddingLeft: "24px"}}>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Username or Email"
                            name="username"
                            fullWidth
                            onChange={handleChange}
                            slotProps={{
                                htmlInput: {size: 35},
                            }}
                            sx={{mt: 3}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            required
                            label="Password"
                            name="password"
                            fullWidth
                            slotProps={{
                                htmlInput: {type: "password", size: 35},
                            }}
                            onChange={handleChange}
                            sx={{mt: 5}}
                        />
                    </Grid>
                    <Grid size={{xs: 12}} sx={{textAlign: 'center'}}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loginState.password.length === 0}
                            onClick={handleSubmit}
                            sx={{mt: 3}}
                            loading={loggingIn}
                        >
                            Login
                        </LoadingButton>
                    </Grid>
                </form>
                <Grid size={{xs: 12}} sx={{textAlign: "center", mb: 3}}>
                    <Button
                        component={Link}
                        to={`/create-account${queryParams !== "" ? `?${queryParams}` : ""}`}
                        variant="outlined"
                        sx={{m: 1}}
                    >
                        Create an Account
                    </Button>
                    <Button
                        component={Link}
                        to="/password-recovery"
                        variant="outlined"
                        color="secondary"
                        sx={{m: 1}}
                    >
                        Forgot your password?
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}