import React, {useContext} from "react";
import {RoundTimelineProps} from "../../data-types";
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent
} from "@mui/lab";
import {Typography, Box} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {parseISO, format, isBefore, formatDistance, isAfter} from "date-fns";
import {RoundTimelineItem} from "./RoundTimelineItem";
import {AddRoundTimelineItem} from "./AddRoundTimelineItem";
import {UserContext} from "../../context";

export function RoundTimeline({roundMeta, competitionMeta, handleDeleteClick}: RoundTimelineProps) {

    const {user} = useContext(UserContext);
    let now = new Date(); // now is created with the user's timezone enforced

    // data returned from the API is an ISO string corresponding to UTC time
    // including the 'Z' indicates UTC and not local timezone point in time
    let competitionStartDate = parseISO(competitionMeta.visibilityDate + 'Z');
    let competitionEndDate = parseISO(competitionMeta.endDate + 'Z');

    const canModify = !!user && (user.role === "admin" || user.role === "event_admin");

    function competitionDistanceString(now: Date, to: Date, start: boolean) {
        if (start) {
            return (isBefore(now, to)) ? `starts in ${formatDistance(now, to)}` : `started ${formatDistance(to, now, {addSuffix: true})}`;
        } else {
            return (isBefore(now, to)) ? `ends in ${formatDistance(now, to)}` : `ended ${formatDistance(to, now, {addSuffix: true})}`;
        }
    }

    return (
        <Box sx={{maxHeight: 500, overflowY: "auto", bgcolor: "#303030"}} component="div">
            <Timeline>
                <TimelineItem position="right" sx={{alignItems: "center"}}>
                    <TimelineOppositeContent>
                        <Typography variant="body2">
                            {format(competitionStartDate, "Pp")}
                        </Typography>
                        <Typography variant="body2">
                            {competitionDistanceString(now, competitionStartDate, true)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator sx={{ml: 2, mr: 2}}>
                        <TimelineDot color={isAfter(now, competitionStartDate) ? "primary" : "secondary"}>
                            <PersonAddIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6">
                            Enrollment Opens
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
                {roundMeta.map((r, i) => <RoundTimelineItem canEdit={competitionMeta.canEdit} competitionId={competitionMeta.competitionId} round={r} handleDeleteClick={handleDeleteClick} key={i} />)}
                {isAfter(competitionEndDate, now) ?
                    <AddRoundTimelineItem competitionId={competitionMeta.competitionId} endConnector={true} roundNumber={roundMeta.length + 1} canEdit={competitionMeta.canEdit || canModify} /> :
                    null
                }
                <TimelineItem position="left" sx={{alignItems: "center"}}>
                    <TimelineOppositeContent>
                        <Typography variant="body2">
                            {format(competitionEndDate, "Pp")}
                        </Typography>
                        <Typography variant="body2">
                            {competitionDistanceString(now, competitionEndDate, false)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator sx={{ml: 2, mr: 2}}>
                        <TimelineDot color={(isAfter(now, competitionEndDate)) ? "secondary" : "grey"}>
                            <StopCircleIcon />
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6">
                            End
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    )
}
